export interface Row {
  name: string
  date: string
  year: number
  file: string
}

const data: Row[] = [
  {
    name: "TAE i",
    date: "2024-07-18",
    year: 2024,
    file: "classement-taei-2024.pdf",
  },
  {
    name: "Tir en salle",
    date: "2024-03-04",
    year: 2024,
    file: "classement-salle-2024.pdf",
  },
  {
    name: "Tir en salle",
    date: "2023-03-13",
    year: 2023,
    file: "classement-salle-2023.pdf",
  },
  {
    name: "Tir en salle",
    date: "2022-02-28",
    year: 2022,
    file: "classement-salle-2022-28-02-2022.pdf",
  },
  {
    name: "Tir en salle",
    date: "2020-02-24",
    year: 2020,
    file: "classement-salle-24-02-2020.pdf",
  },
  {
    name: "Tir campagne",
    date: "2019-12-11",
    year: 2020,
    file: "classement-regional-campagne-11-12-2019.pdf",
  },
  {
    name: "Tir 3D",
    date: "2019-12-11",
    year: 2020,
    file: "classement-regional-3d-11-12-2019.pdf",
  },
  {
    name: "Tir nature",
    date: "2019-12-11",
    year: 2020,
    file: "classement-regional-nature-11-12-2019.pdf",
  },
  {
    name: "Podiums de la saison",
    date: "2019-12-01",
    year: 2019,
    file: "podiums-saison-2018-2019.pdf",
  },
  {
    name: "Tir en salle",
    date: "2019-03-05",
    year: 2019,
    file: "classement-salle-05-03-2019.pdf",
  },
  {
    name: "Tir en salle par équipes",
    date: "2019-02-11",
    year: 2019,
    file: "classement-salle-equipes-11-02-2019.pdf",
  },
  {
    name: "Tir à l'arc en extérieur 50",
    date: "2019-08-01",
    year: 2019,
    file: "classement-regional-tae-50-01-08-2019.pdf",
  },
  {
    name: "Tir à l'arc en extérieur",
    date: "2019-08-01",
    year: 2019,
    file: "classement-regional-tae-01-08-2019.pdf",
  },
  {
    name: "Tir campagne",
    date: "2019-07-15",
    year: 2019,
    file: "classement-regional-campagne-15-07-2019.pdf",
  },
  {
    name: "Tir campagne par équipes",
    date: "2019-07-02",
    year: 2019,
    file: "classement-regional-campagne-equipes-02-07-2019.pdf",
  },
  {
    name: "Tir 3D",
    date: "2019-08-01",
    year: 2019,
    file: "classement-regional-3d-01-08-2019.pdf",
  },
  {
    name: "Tir 3D par équipes",
    date: "2019-07-16",
    year: 2019,
    file: "classement-regional-3d-equipes-16-07-2019.pdf",
  },
  {
    name: "Tir nature",
    date: "2019-07-02",
    year: 2019,
    file: "classement-regional-nature-02-07-2019.pdf",
  },
  {
    name: "Tir fédéral",
    date: "2018-08-27",
    year: 2018,
    file: "classement-regional-federal-27-08-2018.pdf",
  },
  {
    name: "Tir FITA",
    date: "2018-08-07",
    year: 2018,
    file: "classement-regional-fita-07-08-2018.pdf",
  },
  {
    name: "Tir campagne",
    date: "2018-08-13",
    year: 2018,
    file: "classement-regional-campagne-13-08-2018.pdf",
  },
  {
    name: "Tir 3D",
    date: "2018-08-20",
    year: 2018,
    file: "classement-regional-3d-20-08-2018.pdf",
  },
  {
    name: "Tir nature",
    date: "2018-07-04",
    year: 2018,
    file: "classement-regional-nature-04-07-2018.pdf",
  },
  {
    name: "Tir en salle",
    date: "2018-03-07",
    year: 2018,
    file: "classement-salle-07-03-2018.pdf",
  },
]

export default data
